









import {Component, Mixins, Vue} from 'vue-property-decorator'
import QrCode from '@/general/components/qr/QrCode.vue'
import InfoCard from '@/general/components/common/InfoCard.vue'
import ConsignmentService from "@/modules/shipments/services/ConsignmentService";

@Component({
  components: {
    QrCode,
    InfoCard
  }
})
export default class ScanQrcode extends Mixins(ConsignmentService) {
  created() {
    this.$store.commit('isOnConsignment', true)
    let params = this.$route.params

    if (!params.scan) {
      this.getConsignment(this.$route.params.consignment)
    }
  }
}
